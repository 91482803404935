import { Inject, Injectable } from "@angular/core";
import * as fromRoot from "@find-hotels-app/app.reducers";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { CurrencyConversionActionType } from "../actions/currency-conversion-action-type.enum";
import { CurrencyConversionAvailableCurrenciesErrorAction } from "../actions/currency-conversion-available-currencies-error-action";
import { CurrencyConversionIsCurrencySupportedAction } from "../actions/currency-conversion-is-currency-supported-action";
import { CurrencyConversionIsCurrencySupportedErrorAction } from "../actions/currency-conversion-is-currency-supported-error-action";
import { CurrencyConversionActions } from "./../actions/currency-conversion-action-type.enum";
import { CurrencyConversionAvailableCurrenciesDoneAction } from "./../actions/currency-conversion-available-currencies-done-action";
import { CurrencyConversionIsCurrencySupportedDoneAction } from "./../actions/currency-conversion-is-currency-supported-done-action";
import { AvailableCurrenciesServiceInterface } from "./../available-currencies-service-interface";
import { AvailableCurrenciesService } from "./../available-currencies.service";

@Injectable({
  providedIn: "root",
})
export class CurrencyConversionEffects {
  performGetAvailableCurrencies$: Observable<CurrencyConversionActions> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(
        CurrencyConversionActionType[CurrencyConversionActionType.CURRENCY_CONVERSION_GET_AVAILABLE_CURRENCIES]
      ),
      switchMap(() => this.availableCurrenciesService.getAvailableCurrencies()),
      map((availableCurrencies) => new CurrencyConversionAvailableCurrenciesDoneAction(availableCurrencies)),
      catchError((error, caught) => {
        this.store$.dispatch(new CurrencyConversionAvailableCurrenciesErrorAction(error));
        return caught;
      })
    )
  );

  isCurrencySupported$: Observable<CurrencyConversionActions> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(
        CurrencyConversionActionType[CurrencyConversionActionType.CURRENCY_CONVERSION_IS_CURRENCY_SUPPORTED]
      ),
      switchMap((action) =>
        this.availableCurrenciesService.isCurrencySupported(
          (action as CurrencyConversionIsCurrencySupportedAction).currencyCode
        )
      ),
      map((currencySupported) => new CurrencyConversionIsCurrencySupportedDoneAction(currencySupported)),
      catchError((error, caught) => {
        this.store$.dispatch(new CurrencyConversionIsCurrencySupportedErrorAction(error));
        return caught;
      })
    )
  );

  constructor(
    private actions$: Actions<CurrencyConversionActions>,
    private store$: Store<fromRoot.State>,
    @Inject(AvailableCurrenciesService)
    private availableCurrenciesService: AvailableCurrenciesServiceInterface
  ) {}
}
