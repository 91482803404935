import { Inject, Injectable, NgZone } from "@angular/core";
import { Params } from "@angular/router";
import { AnalyticsFacadeService } from "@features/analytics/facades/analytics-facade.service";
import { InitCorporateIdFromProfileAction } from "@find-hotels-app/core/services/search-services/actions/init-corporate-id-from-profile-action";
import { NoopAction } from "@find-hotels-app/core/services/search-services/actions/noop-action";
import { SearchActionType } from "@find-hotels-app/core/services/search-services/actions/search-action-type.enum";
import { SearchFormState } from "@find-hotels-app/core/services/search-services/reducers/search-form-state";
import * as fromSearch from "@find-hotels-app/core/services/search-services/reducers/search.reducer";
import { SearchFilterParamNames } from "@find-hotels-app/core/services/search-services/services/bi/search-filter-parser/models/search-filter-param-names";
import { UserUpdateAction } from "@find-hotels-app/shared/services/api/user/actions/user-update-action";
import { UserFacadeService } from "@find-hotels-app/shared/services/api/user/facades/user-facade.service";
import { User } from "@find-hotels-app/shared/services/api/user/models/user.model";
import { UserService } from "@find-hotels-app/shared/services/api/user/user.service";
import { ViewNavigationAction } from "@find-hotels-app/shared/services/navigation/actions/view-navigation-action";
import { RouterService } from "@find-hotels-app/shared/services/router/router.service";
import { UserStatusType, WINDOW } from "@ihg/common";
import { LogService } from "@ihg/logging";
import { GuestInfo, IGuestInfo } from "@ihg/members";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { concatMap, map, switchMap, withLatestFrom } from "rxjs/operators";

import { RegisteredFreeNightOffers } from "@shared/services/api/registered-offers/models/registered-offers.model";
import { UserActions, UserActionType } from "../actions/user-action-type.enum";
import { UserUpdateDoneAction } from "../actions/user-update-done-action";

@Injectable()
export class UserEffects {
  update$: Observable<UserActions | NoopAction> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(UserActionType[UserActionType.USER_UPDATE]),
      withLatestFrom(this.userFacadeService.user$),
      concatMap(([action, userInStore]: [UserUpdateAction, User]) =>
        action.userStatus === userInStore.status
          ? of(new NoopAction())
          : this.userService.update(action.userStatus).pipe(
              map((user: User) => {
                if (action.initialUpdate === false) {
                  this.userFacadeService.userStatusChanged();
                }
                return new UserUpdateDoneAction(user);
              })
            )
      )
    )
  );

  updateSuccess$: Observable<InitCorporateIdFromProfileAction> = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(UserActionType[UserActionType.USER_UPDATE_DONE]),
      switchMap((action: UserUpdateDoneAction) => {
        //todo: this is nasty, we need to find a better way to do this, timing of this call is important and each page needs to handle it differently
        this.analyticsFacadeService.user(action.user, this.router.getUrlWithoutParams() === "/hotel-search");
        let corporateId = "";
        const user: User = action.user;
        const userStatus: UserStatusType = user.status;
        const guestInfo: GuestInfo = user.guestInfo;

        this.logUserInfo(userStatus, guestInfo);

        if (user.isExplicit) {
          const profiles = guestInfo.travelProfiles;
          if (profiles.length > 0) {
            profiles.forEach((memberTravelProfile) => {
              if (memberTravelProfile) {
                corporateId = memberTravelProfile.corporateAccountRef;
              }
            });
          }
        }
        return of(new InitCorporateIdFromProfileAction(corporateId));
      })
    )
  );

  corporateIDUpdateFromProfile$: Observable<ViewNavigationAction | NoopAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActionType.INIT_CORPORATE_ID_FROM_PROFILE_ACTION),
      withLatestFrom(this.store$.select(fromSearch.selectSearchState)),
      map(([, selectSearchState]: [InitCorporateIdFromProfileAction, SearchFormState]) => {
        const corporateId: string = selectSearchState?.searchCriteria?.corporateId ?? "";
        const hasCorporateId: boolean = corporateId.length > 0;
        const queryParams: Params = this.router.getQueryParams();
        const queryParamsHasCorporateId: boolean = queryParams[SearchFilterParamNames.CORPORATE_ID] !== undefined;
        if (hasCorporateId && !queryParamsHasCorporateId) {
          queryParams[SearchFilterParamNames.CORPORATE_ID] = corporateId;
          const currentRoute: string = this.router.getUrlWithoutParams();
          return new ViewNavigationAction({ path: currentRoute, params: queryParams });
        }
        return new NoopAction();
      })
    )
  );

  constructor(
    private actions$: Actions<UserActions>,
    private userFacadeService: UserFacadeService,
    private userService: UserService,
    private analyticsFacadeService: AnalyticsFacadeService,
    private logService: LogService,
    private store$: Store<fromSearch.State>,
    private router: RouterService,
    @Inject(WINDOW) private window: Window,
    private ngZone: NgZone
  ) {
    this.window.ihg = {
      updateUser: (
        status: UserStatusType,
        guestInfo: IGuestInfo,
        registeredFreeNightOffers: RegisteredFreeNightOffers
      ): void => {
        this.ngZone.run(() => {
          const user = new User(status, new GuestInfo(guestInfo), registeredFreeNightOffers);
          this.store$.dispatch(new UserUpdateDoneAction(user));
        });
      },
    };
  }

  private logUserInfo(status: string, guestInfo: GuestInfo): void {
    this.logService.updateUserInfo({
      signInState: status,
      userId: guestInfo?.rewardsClubNumber ?? "",
    });
  }
}
